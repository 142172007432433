import React from "react";
import {Link} from "gatsby";
import SEO from "../../components/seo";
import "./blog.scss";
import {GetSimpleImage} from "../../helpers/get-simple-image";
import Image from "../../components/image/image";
import HorizontalLine from "../../components/horizontal-line";

const Blog = (props) => {
    return (
        <div location={props.location}>
            <SEO title="Blog" language="pl"/>
            <div className="blog">
                <div className="container">
                    <HorizontalLine classname="white"/>
                    <h2 className="blog__title">Blog - czyli krótkie inormacje o Internecie</h2>
                    <HorizontalLine classname="white"/>
                    <div className="blog__container">
                        <div className="blog__container--box">
                            <Link to="/articles/create-website">
                                <Image image={GetSimpleImage("jak_stworzyc-strone.jpg")[0].node.fluid}
                                       alt="jak stworzyć stronę"/>
                                <h2>Zaczynamy czyli jak stworzyć stronę
                                    internetową</h2>
                            </Link>
                        </div>
                        <div className="blog__container--box">
                            <Link to="/articles/design-website">
                                <Image image={GetSimpleImage("web-design.jpg")[0].node.fluid}
                                       alt="jak projektować stronę"/>
                                <h2>Jak projektować stronę internetową?</h2>
                            </Link>
                        </div>
                        <div className="blog__container--box">
                            <Link to="/articles/promote-website">
                                <Image image={GetSimpleImage("promotions.jpg")[0].node.fluid}
                                       alt="jak promować stronę"/>
                                <h2>Jak skutecznie promować stronę internetową?</h2>
                            </Link>
                        </div>
                        <div className="blog__container--box">
                            <Link to="/articles/history">
                                <Image image={GetSimpleImage("history.jpg")[0].node.fluid}
                                       alt="website history"/>
                                <h2>Kilka słów o historii - czyli od czego się zaczęło</h2>
                            </Link>
                        </div>
                        <div className="blog__container--box">
                            <Link to="/articles/company-website">
                                <Image image={GetSimpleImage("company.jpg")[0].node.fluid}
                                       alt="nowoczesne strony"/>
                                <h2>Jak zbudować stronę firmową</h2>
                            </Link>
                        </div>
                        <div className="blog__container--box">
                            <Link to="/articles/modern-website">
                                <Image image={GetSimpleImage("internet.jpg")[0].node.fluid}
                                       alt="nowoczesne strony"/>
                                <h2>Jak wygląda nowoczesna strona internetowa?</h2>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blog;
