import {graphql, useStaticQuery} from "gatsby";

export const GetSimpleImage = (imageName) => {
    const fluidimages = useStaticQuery(graphql`
        {
            allImageSharp {
                edges {
                    node {
                        fluid {
                            originalName
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }
        }
    `);
    const allFluidImages = fluidimages.allImageSharp.edges;
    return allFluidImages.filter(image => image.node.fluid.originalName === imageName);
};
